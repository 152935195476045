<template>
  <div>
    <div v-if="this.$store.state.loggedIn">You are already logged in</div>
    <div v-else id="login-outer-wrapper" class="outer-view-wrapper">
      <input placeholder="E-Mail" type="text" v-model="email" />
      <input placeholder="Password" type="password" v-model="password" />
      <button @click="googleSignIn()">Login</button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "CMS_Login",
  data: () => ({
    email: "",
    password: "",
  }),
  methods: {
    googleSignIn() {
      var here = this;
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(function () {
          return firebase
            .auth()
            .signInWithEmailAndPassword(here.email, here.password);
        })
        .catch(function (error) {
          var errorCode = error.code;
          var errorMessage = error.message;

          console.error(`${errorCode}: ${errorMessage}`);
        })
        .then(() => {
          window.location.href = "/";
        });
    },
  },
};
</script>

<style scoped>
</style>
